// CountryContext.js
import { createContext, useContext, useState } from 'react';

const CountryContext = createContext();

const countries = [
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
    countryCode: 'uae',
    phoneCode: 'ae'
  },
  { code: 'BH', label: 'Bahrain', phone: '973', countryCode: 'bhr', phoneCode: 'bh' },
  { code: 'KW', label: 'Kuwait', phone: '965', countryCode: 'kwt', phoneCode: 'kw' },
  { code: 'OM', label: 'Oman', phone: '968', countryCode: 'omn', phoneCode: 'om' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966', countryCode: 'ksa', phoneCode: 'sa' },
];

export const useCountry = () => {
  return useContext(CountryContext);
};

export const CountryProvider = ({ children }) => {
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  // const [selectedCountry, setSelectedCountry] = useState(null);

  const setCountry = (country) => {
    setSelectedCountry(country);
  };

  return (
    <CountryContext.Provider value={{ selectedCountry, setCountry, countries }}>
      {children}
    </CountryContext.Provider>
  );
};