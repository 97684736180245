import { createSlice } from '@reduxjs/toolkit'

export const Selectall = createSlice({
  name: 'options',
  initialState: {
    selectedOptions: 0,
    availableOptions: 0,
  },
  reducers: {
    setSelectedOptions: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.selectedOptions = action.payload
    },
    setAvailableOptions: (state, action) => {
      state.availableOptions = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSelectedOptions, setAvailableOptions } = Selectall.actions

export default Selectall.reducer