import React, { Suspense, useEffect } from 'react';
import './App.css';
import { Route, Routes } from "react-router-dom";
import SiteRoutes from './routes/Route';
import Protected from './routes/Protected';
import config from "./config";
import ErrorBoundary from './components/errorBoundary/ErrorBoundary'
import { CircularProgress } from '@mui/material';
import { CountryProvider } from './components/CountryContext';
global.ftk =  localStorage.getItem('appToken') ? localStorage.getItem('appToken') :undefined
global.appUrl=config.BASE_URL;


function App() {
  useEffect(() => {
    if (window.self !== window.top) {
      // The page is being loaded in an iframe
      window.top.location = window.self.location;
    }
  }, []);
  return (
    <div className="App">
      <ErrorBoundary>
        <Suspense fallback={<div style={{margin:"auto", top: 0,bottom: 0,  left: 0, right: 0,position: "absolute", width: 20,  height: 20}}><CircularProgress /></div>}>
        <CountryProvider>
          <Routes>
            {SiteRoutes &&
              SiteRoutes.map((route, i) => {
                return (
                  <Route
                    key={route.title + i}
                    exact={route.path === '/' ? true : false}
                    path={route.path}
                    element={<Protected Componant={route.component} />}
                  ></Route>
                );
              })}
          </Routes>
          </CountryProvider>
        </Suspense>
        </ErrorBoundary>
    </div>
  );
}
export default App;