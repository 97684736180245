import { lazy } from "react";

import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import * as actions from "../components/pages/commenServices/Services";
import NoPageFound from "../components/login/NoPageFound";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const ImageViewPage = lazy(() => import("../components/pages/CDM/ImageViewPage"));
const AssemblyRequest = lazy(() => import("../components/pages/seviceRequest/AssemblyRequest"));
const Technician = lazy(() => import("../components/pages/technicianRequest/Technician"));
const Login = lazy(() => import("../components/login/Login"));
const ServiceDetails = lazy(() => import("../components/pages/seviceRequest/ServiceDetails"));
const ViewCommentDetails = lazy(() =>
  import("../components/pages/seviceRequest/ViewCommentDetails")
);
const PageUnderCon = lazy(() => import("../components/login/PageUnderCon"));
const CdmDeposite = lazy(() => import("../components/pages/CDM/CdmDeposite"));

const isPermissionForService = actions.hasPermission("/assembly-request", "r");
const isPermissionForTechnician = actions.hasPermission("/technician", "r");


const Route = [
  {
    title: "Login",
    path: "/",
    showInMenu: false,
    role: "all",
    icon: <DashboardCustomizeIcon />,
    component: <Login />,
  },
  isPermissionForService && {
    title: "Assembly request",
    path: "/assembly-request",
    showInMenu: true,
    role: "all",
    icon: <EngineeringIcon />,
    component: <AssemblyRequest />,
  },
  isPermissionForTechnician && {
    title: "Technician",
    path: "/technician",
    showInMenu: true,
    role: "all",
    icon: <ManageAccountsIcon />,
    component: <Technician />,
  },

  {
    title: "Assembly request Details",
    path: "/assembly-request/serviceDetail/:serviceID",
    showInMenu: false,
    role: "all",
    icon: <EngineeringIcon />,
    component: <ServiceDetails />,
  },
  {
    title: "Assembly request  comment Details",
    path: "/assembly-request/serviceDetail/comment/:serviceID",
    showInMenu: false,
    role: "all",
    icon: <EngineeringIcon />,
    component: <ViewCommentDetails />,
  },
  
  isPermissionForTechnician &&{
    title: "CdmDeposite",
    path: "/CdmDeposite",
    showInMenu: true,
    role: "all",
    icon: <LocalAtmIcon />,
    component: <CdmDeposite />,
  },
  {
    title: "puc",
    path: "/page-under-constraction",
    showInMenu: false,
    role: "all",
    icon: <ThumbsUpDownIcon />,
    component: <PageUnderCon />,
  },
  {
    title: "NoPageFound",
    path: "*",
    showInMenu: false,
    role: "all",
    icon: <DashboardCustomizeIcon />,
    component: <NoPageFound />,
  },
  {
    title: "Image View Page",
    path: "/url/:imgUrl",
    showInMenu: false,
    role: "all",
    icon: <HighlightOffIcon />,
    component: <ImageViewPage />,
  },
];

export default Route;
